@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
@featured-job-bg: #fcffde;

body, label, .checkbox label {
	font-weight: 300;
}

footer {
  background-color: @well-bg;
}

footer a {
  color: #777777;
}

@media(min-width:768px) {
  .navbar {
    padding: 25px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    .navbar-brand {
      font-size: 2em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
  .navbar.navbar-shrink {
    padding: 10px 0;
    .navbar-brand {
      font-size: 1.5em;
    }
  }

}
footer {
  padding: 25px 0;
  border-top: 1px solid @page-header-border-color;
  margin-top: 25px;
}

.sidebar {
  > .sidebar-widget {
    margin-bottom: @line-height-computed;

    > .sidebar-widget-title {
      padding-bottom: ((@line-height-computed / 2) - 1);
      border-bottom: 1px solid @page-header-border-color;
    }
  }
}

#job-list {
  > .job {
    margin-bottom: @line-height-computed;
    padding-bottom: ((@line-height-computed / 2) - 1);
    padding-top: ((@line-height-computed / 2) - 1);

    &:nth-child(odd) {
      background-color: @table-bg-accent;
    }

    &.featured {
      background-color: @featured-job-bg;
    }

    > div {
      > .job-title {
        padding-left: @grid-gutter-width;

        > a {
          font-weight: bold;
        }
      }

      > .job-type {
        padding-top: ((@line-height-computed / 2) - 1);
      }

      > .job-location {
        padding-top: ((@line-height-computed / 2) - 1);
      }
    }
  }
}

.social-list li {
  margin: 3px 0;
}